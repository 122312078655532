import React from "react"
import "../css/style.css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function Navbar() {
  const data = useStaticQuery(graphql`
    query NAVBAR {
      logo: file(relativePath: { eq: "Logo.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
    }
  `)

  return (
    <section id="top" id="burgernavbar-style" className="section1">
      <input id="main-menu-checkbox" type="checkbox" />

      <header className="header-area">
        <Img
          className="navbar-logo"
          alt=""
          fluid={data.logo.childImageSharp.fluid}
        />
        <div className="grid-container-burgernav">
          <div className="burger-grid">
            <label
              for="main-menu-checkbox"
              className="zoom menu-toggle label"
              id="main-menu-toggle"
            >
              <span className="sr-only">Open</span>
              <span className="fa fa-bars"></span>
            </label>

            <div className="d-inline-flex">
              <nav
                id="main-menu"
                role="navigation"
                className="main-menu"
                aria-expanded="false"
                aria-label="Main menu"
              >
                <label
                  for="main-menu-checkbox"
                  className="menu-close zoom"
                  id="main-menu-close"
                >
                  <span className="sr-only">Close</span>
                  <span className="fa fa-close" aria-hidden="true"></span>
                </label>

                <ul>
                  <li className="nav-item">
                    <a href="/#mision" className="nav-link">
                      Nosotros
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/#vision" className="nav-link">
                      Marca
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/#valores" className="nav-link">
                      Blog
                    </a>
                  </li>
                </ul>
              </nav>

              <label
                for="main-menu-checkbox"
                className="backdrop"
                tabindex="-1"
                aria-hidden="true"
                hidden
              ></label>
            </div>
          </div>
        </div>
      </header>
    </section>
  )
}
