import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../css/style.css"

export default function Footer() {
  const data = useStaticQuery(graphql`
    query Footer {
      icon: file(relativePath: { eq: "icon.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
    }
  `)

  return (
    <footer className="footer-section">
      <div className="footer-section-line"></div>
      <div className="page-border grid-container-footer">
        <div>
          <p className="footer-text-1">CONTACTO</p>
          <p className="footer-text-1">TRABAJA CON NOSOTROS</p>
          <p className="footer-text-1">TÉRMINOS Y CONDICIONES</p>
          <p className="footer-text-1">AVISO DE PRIVACIDAD</p>
        </div>
        <div>
          <h3 className="footer-text-1">METODOS DE PAGO</h3>
        </div>
        <div></div>
      </div>
    </footer>
  )
}
